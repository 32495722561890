import { defineMessage } from "react-intl";

export const LANG_COMMON_FROM_DATE = defineMessage({
    id: "common.term.from",
    defaultMessage: "De:",
    description: "Filtro inicial por intervalo de data 'De:'"
});

export const LANG_COMMON_TRY_AGAIN = defineMessage({
    id: "common.term.try_again",
    defaultMessage: "Tentar novamente",
});

export const LANG_COMMON_YES = defineMessage({
    id: "common.term.yes",
    defaultMessage: "Sim",
});

export const LANG_COMMON_NO = defineMessage({
    id: "common.term.no",
    defaultMessage: "Não",
});

export const LANG_COMMON_DATA = defineMessage({
    id: "common.term.data",
    defaultMessage: "Dados",
});

export const LANG_COMMON_OTHER_DATA = defineMessage({
    id: "common.term.other_data",
    defaultMessage: "Outros dados",
});

export const LANG_COMMON_FILES = defineMessage({
    id: "common.term.files",
    defaultMessage: "Arquivos",
});

export const LANG_COMMON_SELECT_FILE = defineMessage({
    id: "common.term.select_file",
    defaultMessage: "Selecionar Arquivo",
});

export const LANG_COMMON_BACK = defineMessage({
    id: "common.term.back",
    defaultMessage: "Voltar",
});

export const LANG_COMMON_TO_DATE = defineMessage({
    id: "common.term.to",
    defaultMessage: "Até:",
    description: "Filtro final por intervalo de data 'Até:'"
});

export const LANG_COMMON_BORN_ALIVE = defineMessage({
    id: "common.term.born_alive",
    defaultMessage: "Nascidos vivos"
});

export const LANG_COMMON_CONFIRMED = defineMessage({
    id: "common.term.confirmed",
    defaultMessage: "Confirmado"
});

export const LANG_COMMON_NO_CONFIRMED = defineMessage({
    id: "common.term.no_confirmed",
    defaultMessage: "Não confirmado"
});

export const LANG_COMMON_OPTIONS = defineMessage({
    id: "common.term.options",
    defaultMessage: "OPÇÕES",
    description: "Título usado no cabeçalho de opções"
});

export const LANG_COMMON_PROFILE = defineMessage({
    id: "common.term.profile",
    defaultMessage: "Perfil"
});

export const LANG_COMMON_DASHBOARD = defineMessage({
    id: "common.term.dashboard",
    defaultMessage: "DASHBOARD",
    description: "Palavra DASHBOARD"
});

export const LANG_COMMON_CLINIC = defineMessage({
    id: "common.term.clinic",
    defaultMessage: "Clínica",
    description: "Palavra Clínica"
});

export const LANG_COMMON_CLINICS = defineMessage({
    id: "common.term.clinics",
    defaultMessage: "Clínicas"
});

export const LANG_COMMON_USERS = defineMessage({
    id: "common.term.users",
    defaultMessage: "Usuários"
});

export const LANG_COMMON_USER = defineMessage({
    id: "common.term.user",
    defaultMessage: "Usuário"
});

export const LANG_COMMON_PASSWORD = defineMessage({
    id: "common.term.password",
    defaultMessage: "Senha"
});

export const LANG_COMMON_PASSWORD_MSG = defineMessage({
    id: "common.term.password_msg",
    defaultMessage: "Deixe em branco para manter a senha atual."
});

export const LANG_COMMON_USE_TERMS = defineMessage({
    id: "common.term.use_terms",
    defaultMessage: "Termos"
});

export const LANG_COMMON_INSTRUCTIONS = defineMessage({
    id: "common.term.instructions",
    defaultMessage: "Orientações"
});

export const LANG_COMMON_SUBSCRIPTION = defineMessage({
    id: "common.term.subscription",
    defaultMessage: "Assinatura"
});

export const LANG_SUBSCRIPTION_PAYMENTS = defineMessage({
    id: "common.subscription.payments",
    defaultMessage: "Pagamentos"
});

export const LANG_SUBSCRIPTION_STATUS_PAID = defineMessage({
    id: "common.subscription.status_paid",
    defaultMessage: "PAGO"
});

export const LANG_SUBSCRIPTION_STATUS_PENDING = defineMessage({
    id: "common.subscription.status_pending",
    defaultMessage: "PENDENTE"
});

export const LANG_SUBSCRIPTION_STATUS_CANCELLED = defineMessage({
    id: "common.subscription.status_cancelled",
    defaultMessage: "CANCELADO"
});

export const LANG_SUBSCRIPTION_DURATION = defineMessage({
    id: "common.subscription.duration",
    defaultMessage: "Período"
});

export const LANG_SUBSCRIPTION_RECEIPT = defineMessage({
    id: "common.subscription.receipt",
    defaultMessage: "Nota fiscal"
});

export const LANG_SUBSCRIPTION_PLAN = defineMessage({
    id: "common.subscription.plan",
    defaultMessage: "Plano"
});

export const LANG_SUBSCRIPTION_RECURRENCY_M = defineMessage({
    id: "common.subscription.recurrency_m",
    defaultMessage: "Mensal"
});

export const LANG_SUBSCRIPTION_RECURRENCY_Y = defineMessage({
    id: "common.subscription.recurrency_y",
    defaultMessage: "Anual"
});

export const LANG_SUBSCRIPTION_INVOICE = defineMessage({
    id: "common.subscription.invoice",
    defaultMessage: "Boleto"
});

export const LANG_COMMON_PAIRING = defineMessage({
    id: "common.term.pairing",
    defaultMessage: "Pareamento",
    description: "Palavra Pareamento"
});

export const LANG_COMMON_STATUS = defineMessage({
    id: "common.term.status",
    defaultMessage: "Status"
});

export const LANG_COMMON_MONTH = defineMessage({
    id: "common.term.month",
    defaultMessage: "mês"
});

export const LANG_COMMON_PAIRINGS = defineMessage({
    id: "common.term.pairings",
    defaultMessage: "PAREAMENTOS",
    description: "Palavra Pareamentos no plural"
});

export const LANG_COMMON_SEARCH = defineMessage({
    id: "common.term.search",
    defaultMessage: "Buscar",
    description: "Palavra 'buscar' comumente usado nos botões"
});

export const LANG_COMMON_CLEAR = defineMessage({
    id: "common.term.clear",
    defaultMessage: "Limpar"
});

export const LANG_COMMON_FIND = defineMessage({
    id: "common.term.find",
    defaultMessage: "Pesquisar" 
});

export const LANG_COMMON_NEW = defineMessage({
    id: "common.term.new",
    defaultMessage: "Novo" 
});

export const LANG_COMMON_GENDER = defineMessage({
    id: "common.term.gender",
    defaultMessage: "Sexo" 
});

export const LANG_COMMON_BLANK = defineMessage({
    id: "common.term.blank",
    defaultMessage: "Não informado" 
});

export const LANG_COMMON_DEFAULT = defineMessage({
    id: "common.term.default",
    defaultMessage: ""
});

export const LANG_COMMON_LOADING = defineMessage({
    id: "common.term.loading",
    defaultMessage: "Carregando..."
});

export const LANG_COMMON_NO_RESULTS = defineMessage({
    id: "common.term.no_results",
    defaultMessage: "Sem resultados"
});

export const LANG_COMMON_FEMALE = defineMessage({
    id: "common.term.female",
    defaultMessage: "Feminino"
});

export const LANG_COMMON_MALE = defineMessage({
    id: "common.term.male",
    defaultMessage: "Masculino"
});

export const LANG_COMMON_ADD = defineMessage({
    id: "common.term.add",
    defaultMessage: "Adicionar"
});

export const LANG_COMMON_HELLO = defineMessage({
    id: "common.term.hello",
    defaultMessage: "Olá"
});

export const LANG_COMMON_LOGOUT = defineMessage({
    id: "common.term.logout",
    defaultMessage: "Sair"
});

export const LANG_COMMON_MEDICAL_RECORD_NUMBER = defineMessage({
    id: "common.term.medical_record_number",
    defaultMessage: "Nº do Prontuário"
});

export const LANG_COMMON_DELETE_CONFIRM = defineMessage({
    id: "common.term.delete_confirm",
    defaultMessage: "Deseja realmente excluir esse registro?"
});

export const LANG_COMMON_DELETE_SUCCESS = defineMessage({
    id: "common.term.delete_success",
    defaultMessage: "Registro excluído com sucesso!"
});

export const LANG_COMMON_UPDATE_SUCCESS = defineMessage({
    id: "common.term.update_success",
    defaultMessage: "Registro alterado com sucesso!"
});

export const LANG_COMMON_INSERT_SUCCESS = defineMessage({
    id: "common.term.insert_success",
    defaultMessage: "Registro adicionado com sucesso!"
});

export const LANG_COMMON_DELETE_ERROR = defineMessage({
    id: "common.term.delete_error",
    defaultMessage: "Houve um erro ao excluir"
});

export const LANG_COMMON_NAME = defineMessage({
    id: "common.term.name",
    defaultMessage: "Nome"
});

export const LANG_COMMON_SIMILARITY = defineMessage({
    id: "common.term.similarity",
    defaultMessage: "Similaridade"
});

export const LANG_COMMON_DATE = defineMessage({
    id: "common.term.date",
    defaultMessage: "Data"
});

export const LANG_COMMON_PREVIOUS = defineMessage({
    id: "common.term.previous",
    defaultMessage: "Anterior"
});

export const LANG_COMMON_NEXT = defineMessage({
    id: "common.term.next",
    defaultMessage: "Próximo"
});

export const LANG_COMMON_ALL = defineMessage({
    id: "common.term.all",
    defaultMessage: "Todos"
});

export const LANG_COMMON_AVAILABLE = defineMessage({
    id: "common.term.available",
    defaultMessage: "Disponíveis"
});

export const LANG_COMMON_CHOOSE = defineMessage({
    id: "common.term.choose",
    defaultMessage: "Selecione"
});

export const LANG_COMMON_CANCEL = defineMessage({
    id: "common.term.cancel",
    defaultMessage: "Cancelar"
});

export const LANG_COMMON_SAVE = defineMessage({
    id: "common.term.save",
    defaultMessage: "Salvar"
});

export const LANG_COMMON_CONFIRM = defineMessage({
    id: "common.term.confirm",
    defaultMessage: "Confirmar"
});

export const LANG_COMMON_HEADER_CONFIRM = defineMessage({
    id: "common.header.confirm",
    defaultMessage: "Confirmação"
});

export const LANG_COMMON_HEADER_NEW_PAIRING = defineMessage({
    id: "common.header.new_pairing",
    defaultMessage: "Novo Pareamento"
});

export const LANG_WARNING_FILL_ALL = defineMessage({
    id: "common.warning.fill_all",
    defaultMessage: "Preencha corretamente todos os campos!"
});

export const LANG_ERROR = defineMessage({
    id: "common.error",
    defaultMessage: "Houve um erro, tente novamente."
});

export const LANG_LIMIT_ERROR = defineMessage({
    id: "common.limit_error",
    defaultMessage: "Você atingiu o limite de cadastros do seu plano."
});

export const LANG_STATE = defineMessage({
    id: "common.term.state",
    defaultMessage: "Estado"
});

export const LANG_CITY = defineMessage({
    id: "common.term.city",
    defaultMessage: "Cidade"
});

export const LANG_DISTRICT = defineMessage({
    id: "common.term.district",
    defaultMessage: "Bairro"
});

export const LANG_ROD_AVAILABILITY = defineMessage({
    id: "donor.rod_availability",
    defaultMessage: "Disponibilidade de gametas"
});

export const LANG_COMMON_ID = defineMessage({
    id: "common.term.id",
    defaultMessage: "ID"
});

export const LANG_COMMON_CODE = defineMessage({
    id: "common.term.code",
    defaultMessage: "Código"
});

export const LANG_COMMON_YEAR = defineMessage({
    id: "common.term.year",
    defaultMessage: "ano"
});

export const LANG_COMMON_YEARS = defineMessage({
    id: "common.term.years",
    defaultMessage: "anos"
});


// PERSON PROPERTIES
export const LANG_PERSON_BLOOD_TYPE = defineMessage({
    id: "person.blood_type",
    defaultMessage: "Tipo sanguíneo"
});

export const LANG_PERSON_RH_FACTOR = defineMessage({
    id: "person.rh_factor",
    defaultMessage: "Fator RH"
});

export const LANG_PERSON_RACE = defineMessage({
    id: "person.race",
    defaultMessage: "Etnia"
});

export const LANG_PERSON_HEIGHT = defineMessage({
    id: "person.height",
    defaultMessage: "Altura (metros)"
});

export const LANG_PERSON_WEIGHT = defineMessage({
    id: "person.weight",
    defaultMessage: "Peso (kg)"
});

export const LANG_PERSON_BMI = defineMessage({
    id: "person.bmi",
    defaultMessage: "IMC"
});

export const LANG_PERSON_BIRTH_DATE = defineMessage({
    id: "person.birth_date",
    defaultMessage: "Data de nascimento"
});

export const LANG_PERSON_AGE = defineMessage({
    id: "person.age",
    defaultMessage: "Idade (anos)"
});

export const LANG_PERSON_AGE_ROD = defineMessage({
    id: "person.age_rod",
    defaultMessage: "Idade na doação (anos)"
});

export const LANG_PERSON_SKIN_COLOR = defineMessage({
    id: "person.skin_color",
    defaultMessage: "Cor da pele"
});

export const LANG_PERSON_HAIR_COLOR = defineMessage({
    id: "person.hair_color",
    defaultMessage: "Cor do cabelo"
});

export const LANG_PERSON_HAIR_TYPE = defineMessage({
    id: "person.hair_type",
    defaultMessage: "Tipo do cabelo"
});

export const LANG_PERSON_EYES_COLOR = defineMessage({
    id: "person.eyes_color",
    defaultMessage: "Cor dos olhos"
});

export const LANG_PERSON_OTHER_INFO = defineMessage({
    id: "person.other_info",
    defaultMessage: "OUTRAS INFORMAÇÕES"
});

export const LANG_PERSON_OTHER_INFO_DETAILS = defineMessage({
    id: "person.other_info_details",
    defaultMessage: "(HISTÓRIA PATOLÓGIA PREGRESSA / HISTÓRIA FAMILIAR E SOCIAL / RESULTADO DE EXAMES)"
});

export const LANG_PERSON_SCHOOLING = defineMessage({
    id: "person.schooling",
    defaultMessage: "Escolaridade"
});

export const LANG_PERSON_HAS_CHILDREN = defineMessage({
    id: "person.has_children",
    defaultMessage: "Filhos",
    description: "Indica se a pessoa tem filhos, a resposta é sempre sim ou não."
});

export const LANG_PERSON_OCCUPATION = defineMessage({
    id: "person.occupation",
    defaultMessage: "Profissão"
});

export const LANG_PERSON_HOBBIES = defineMessage({
    id: "person.hobbies",
    defaultMessage: "Hobbies"
});

export const LANG_PERSON_PICTURE = defineMessage({
    id: "person.picture",
    defaultMessage: "Foto atual"
});

export const LANG_PERSON_PICTURE_KID = defineMessage({
    id: "person.picture_kid",
    defaultMessage: "Foto de criança"
});

export const LANG_PERSON_RODS = defineMessage({
    id: "person.rods",
    defaultMessage: "Hastes / Vials"
});

export const LANG_PERSON_NEW_ROD = defineMessage({
    id: "person.new_rod",
    defaultMessage: "Nova Haste / Vial"
});

export const LANG_PERSON_ROD_FROZEN = defineMessage({
    id: "person.rod_frozen",
    defaultMessage: "CONGELADO"
});

export const LANG_PERSON_ROD_FRESH = defineMessage({
    id: "person.rod_fresh",
    defaultMessage: "A FRESCO"
});

export const LANG_PERSON_ROD_TYPE = defineMessage({
    id:"person.rod_type",
    defaultMessage:"Forma de Uso",
    description:"Tipo / Forma de uso da haste (congelado ou a fresco)",
});

export const LANG_PERSON_ROD_FREEZING_DATE = defineMessage({
    id: "person.rod_freezing_date",
    defaultMessage: "Data Congelamento"
});

export const LANG_PERSON_ROD_OVULES_QTY = defineMessage({
    id: "person.rod_ovules_qty",
    defaultMessage: "Quantidade de gametas"
});

export const LANG_PERSON_ROD_USED = defineMessage({
    id: "person.rod_used",
    defaultMessage: "Utilizado"
});

export const LANG_PERSON_ROD_IS_USED = defineMessage({
    id: "person.rod_is_used",
    defaultMessage: "CONSTA EM OUTRO PAREAMENTO"
});

export const LANG_FILE_DELETE_CONFIRMATION = defineMessage({
    id: "file.delete_confirmation",
    defaultMessage: "Deseja realmente excluir esse arquivo?"
});

export const LANG_FILE_TRY_AGAIN = defineMessage({
    id: "file.try_again",
    defaultMessage: "Tentar novamente"
});

export const LANG_FILE_VIEW_FILE = defineMessage({
    id: "file.view_file",
    defaultMessage: "Ver arquivo"
});


export const LANG_COMMON_WELCOME = defineMessage({
    id: "common.term.welcome",
    defaultMessage: "Seja bem-vindo"
});

export const LANG_COMMON_LOGIN = defineMessage({
    id: "common.term.login",
    defaultMessage: "Login"
});

export const LANG_AUTH_DO_LOGIN = defineMessage({
    id: "auth.do_login",
    defaultMessage: "Faça seu Login"
});

export const LANG_AUTH_CREATE_ACCOUNT = defineMessage({
    id: "auth.create_account",
    defaultMessage: "Criar Conta"
});

export const LANG_AUTH_FORGET_PASSWORD = defineMessage({
    id: "auth.forget_password",
    defaultMessage: "Esqueceu sua senha?"
});


export const LANG_COMMON_ERROR = defineMessage({
    id: "common.term.error",
    defaultMessage: "Erro"
});

export const LANG_SUBSCRIPTION_EXPIRED = defineMessage({
    id: "subscription.expired",
    defaultMessage: "Sua assinatura expirou!"
});

export const LANG_AUTH_2FA_TITLE = defineMessage({
    id: "auth.2fa_title",
    defaultMessage: "Autenticação de Dois Fatores"
});

export const LANG_AUTH_2FA_OTP_ERROR = defineMessage({
    id: "auth.2fa_otp_error",
    defaultMessage: "Código inválido, tente novamente."
});

export const LANG_COMMON_BUSINESS_NAME = defineMessage({
    id: "common.term.business_name",
    defaultMessage: "Razão Social"
});

export const LANG_COMMON_BUSINESS_ID = defineMessage({
    id: "common.term.business_id",
    defaultMessage: "CNPJ"
});

export const LANG_COMMON_PERSON_ID = defineMessage({
    id: "common.term.person_id",
    defaultMessage: "CPF"
});

export const LANG_COMMON_PHONE = defineMessage({
    id: "common.term.phone",
    defaultMessage: "Celular"
});

export const LANG_COMMON_ZIPCODE = defineMessage({
    id: "common.term.zipcode",
    defaultMessage: "CEP"
});

export const LANG_COMMON_STREET = defineMessage({
    id: "common.term.street",
    defaultMessage: "Rua"
});

export const LANG_COMMON_NUMBER = defineMessage({
    id: "common.term.number",
    defaultMessage: "Número"
});

export const LANG_COMMON_COMPLEMENT = defineMessage({
    id: "common.term.complement",
    defaultMessage: "Complemento"
});

export const LANG_COMMON_DISTRICT = defineMessage({
    id: "common.term.district",
    defaultMessage: "Bairro"
});

export const LANG_COMMON_IS_REQUIRED = defineMessage({
    id: "common.term.is_required",
    defaultMessage: "é obrigatório"
});