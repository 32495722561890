import React, {FC, useContext} from "react";
import {Badge, Button, Card, Image} from "react-bootstrap";
import {PairedPersonType} from "../../types/PersonType";
import {PersonModel} from "../../models/PersonModel";
import {ImageUploadProvider, ImageUploadProviderContext} from "../../providers/ImageUploadProvider";
import ImageUploader from "../../components/ImageUploader";
import clsx from "clsx";
import {useAuth} from "../../hooks/useAuth";
import moment from "moment";
import { useIntl } from "react-intl";
import { LANG_COMMON_CHOOSE, LANG_PERSON_AGE, LANG_PERSON_AGE_ROD, LANG_PERSON_EYES_COLOR, LANG_PERSON_HAIR_COLOR, LANG_PERSON_HAIR_TYPE, LANG_PERSON_HEIGHT, LANG_PERSON_RACE, LANG_PERSON_SKIN_COLOR } from "../../lang";
import { OptionsContext } from "../../providers/OptionsContextProvider";

interface Props {
    paired: PairedPersonType
    onSelect: (paired: PairedPersonType) => void
    stared?: boolean
    showChildPhoto?: boolean
    type?: string
    onSelectReport: (selected: boolean) => void
}

const PairedPersonCard: FC<Props> = ({paired, onSelect, stared=false, showChildPhoto=false, type = "R", onSelectReport}) => {
    const intl = useIntl();
    const {hasAbility} = useAuth();
    const options = useContext(OptionsContext);

    const calculateAge = (birth_date: string | undefined) => {
        if(birth_date === undefined) return "0";
        const moment_birth_date = moment(birth_date, 'DD/MM/YYYY');
        if(moment_birth_date.isValid()) {
            return moment().diff(moment_birth_date, 'years');
        }
        return 0;
    }

    const calculateAgeRod = (birth_date: string | undefined, rod_date: string | null | undefined) => {
        if(birth_date === undefined || typeof rod_date !== 'string') return "0";
        const moment_birth_date = moment(birth_date, 'DD/MM/YYYY');
        const moment_rod_date = moment(rod_date, 'DD/MM/YYYY');
        if(moment_birth_date.isValid() && moment_rod_date.isValid()) {
            return moment_rod_date.diff(moment_birth_date, 'years');
        }
        return 0;
    }

    const id = (type === "R") ? paired.donor : paired.receiver;

    return (
        <div className="paired-person-card">
            <ImageUploadProvider url={(!showChildPhoto) ? `/api/person/${id}/photo` : `/api/person/${id}/photo/child`} onChange={() => {}}>
                <ImageUploadProviderContext.Consumer>
                    {({url, onChange, onRemove, loading, hasUploadError, hasDownloadError, download}) => (
                        <ImageUploader
                            hasUploadError={hasUploadError}
                            hasDownloadError={hasDownloadError}
                            download={download}
                            loading={loading}
                            height={304}
                            width={304}
                            url={url}
                            onChange={onChange}
                            onRemove={onRemove}
                            uploadUrl={`/api/person/${id}/photo`}
                            edit={false}
                            removeUrl={""}
                            photoLabel={`${paired.name} - ${paired.medical_record_number}`}
                            photoSubLabel={(hasAbility("PAIRING_DETAILS")) ? `${paired.soft_similarity.replace(".", ",")}%` : `${paired.similarity.replace(".", ",")}%`}
                            noGradient={true}
                            stared={false}
                            showCheckbox={true}
                            onSelect={onSelectReport}
                        />
                    )}
                </ImageUploadProviderContext.Consumer>
            </ImageUploadProvider>
            <div className={'p-3'}>
                {hasAbility("PAIRING_DETAILS") && (
                    <>
                        <hr/>
                        <h6>Aspectos avaliados</h6>
                        <ul>
                            <li>Similaridade do rosto AWS: {paired.face_similarity !== null ? paired.face_similarity.replace(".", ",") : "0,00"}%</li>
                            <li>Similaridade do rosto ISIS: {paired.soft_face_similarity !== null ? paired.soft_face_similarity.replace(".", ",") : "0,00"}%</li>
                            <li>Similaridade dos fenótipos: {paired.soft_physical_similarity !== null ? paired.soft_physical_similarity.replace(".", ",") : "0,00"}%</li>
                            <li>Similaridade cor da pele: {paired.skin_color_similarity.replace(".", ",")}%</li>
                            <li>Similaridade cor dos olhos: {paired.eyes_color_similarity.replace(".", ",")}%</li>
                            <li>Similaridade tipo de cabelo: {paired.hair_type_similarity.replace(".", ",")}%</li>
                            <li>Similaridade cor do cabelo: {paired.hair_color_similarity.replace(".", ",")}%</li>
                            <li>Similaridade de altura: {paired.height_similarity.replace(".", ",")}%</li>
                        </ul>
                    </>
                )}
                <hr/>
                <ul>
                    <li>{intl.formatMessage(LANG_PERSON_RACE)}: {PersonModel().getOption(paired.race, options.personOptions?.getRaceTypes())?.label}</li>
                    <li>{intl.formatMessage(LANG_PERSON_SKIN_COLOR)}: {PersonModel().getOption(paired.skin_color, options.personOptions?.getSkinColors())?.label}</li>
                    <li>{intl.formatMessage(LANG_PERSON_EYES_COLOR)}: {PersonModel().getOption(paired.eyes_color, options.personOptions?.getEyesColors())?.label}</li>
                    <li>{intl.formatMessage(LANG_PERSON_HAIR_COLOR)}: {PersonModel().getOption(paired.hair_color, options.personOptions?.getHairColors())?.label}</li>
                    <li>{intl.formatMessage(LANG_PERSON_HAIR_TYPE)}: {PersonModel().getOption(paired.hair_type, options.personOptions?.getHairTypes())?.label}</li>
                    <li>{intl.formatMessage(LANG_PERSON_HEIGHT)}: {paired.height_number}m</li>
                    <li>{intl.formatMessage(LANG_PERSON_AGE)}: {calculateAge(paired.birth_date)} anos</li>
                    <li>{intl.formatMessage(LANG_PERSON_AGE_ROD)}: {calculateAgeRod(paired.birth_date, paired.last_freezed_rod_date)} anos</li>
                </ul>
                <div className="d-grid gap-2">
                    <Button
                        variant="primary"
                        className="btn-custom bg-custom-gradient btn-custom-small text-uppercase w-100"
                        onClick={() => onSelect(paired)}
                    >{intl.formatMessage(LANG_COMMON_CHOOSE)}</Button>
                </div>
            </div>
        </div>
    );
}

export default PairedPersonCard;